import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Pricing from "../components/sections/Pricing"
import VirtualAssistant from "./virtual-assistant"

const PricingPage = () => (
  <Layout>
    <SEO title="Pricing - VERIFiSMART" description="Explore VerifiSmart's Pricing Plans - We offer Flexible Pricing Plans for Affordable and Transparent Copyright Protection Solutions. Pay only for what you need. Find the perfect pricing package for your business needs. Secure, reliable, and cost-effective copyright registration and verification services await you!"/>
    <Pricing />
    <VirtualAssistant />
  </Layout>
)

export default PricingPage
