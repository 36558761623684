import React from "react"
import { Box } from "@chakra-ui/core"
import IframeResizer from "iframe-resizer-react"

import {Bold, Paragraph, SectionHeading, SubHeading} from "../ui-elements"
import {Link as GatsbyLink} from "gatsby";

const Pricing = () => {
  return (
    <Box py="16" id="pricing" color="theme-dark">
      <Box px="4" mx="auto" w="100%" maxW="containers.xl">
        <SectionHeading>Pricing</SectionHeading>
        <SubHeading mb="4">
          Pay only for the services you need. Chose the plan that best fits your
          needs.
        </SubHeading>

        <IframeResizer
          width="100%"
          src={process.env.GATSBY_APP_URL + "/embed/pricing"}
        />
        <Paragraph>
          You can learn more about us on our{" "}
          <Bold
              textDecoration="underline"
              to="/about-us"
              as={GatsbyLink}
          >About Us
          </Bold> page.
          Visit our{" "}
          <Bold
              textDecoration="underline"
              to="/"
              as={GatsbyLink}
          >Home Page</Bold>  to explore our services.
        </Paragraph>
      </Box>
    </Box>
  )
}

export default Pricing
